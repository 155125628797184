







































































































import Vue, {PropType} from "vue";
import { SubscriptionProductsData, VenueData } from "@/types";
import {mapActions, mapGetters} from "vuex";
import Venue from "@/router/views/venues/Venue.vue";

export default Vue.extend({
  name: "select-venues-modal" as string,

  props: {
    id: Number as PropType<number>,
    addModalId: Number as PropType<number>,
    data: Array as PropType<Array<VenueData>>,
    selectedData: Array as PropType<Array<number>>,
    onlyRead: Boolean as PropType<boolean>,
    expiredVenues: Array as PropType<Array<number>>,
    disabledVenues: Array as PropType<Array<number>>,
    isEdit: Boolean as PropType<boolean>,
    isEdited: Boolean as PropType<boolean>,
    tooltipDisabledInfo: String as PropType<string>,
    tooltipBasicInfo: String as PropType<string>,
    tooltipAudioMessages: String as PropType<string>,
    resetSelected: Boolean as PropType<boolean>
  },

  data() {
    return {
      allSelected: false,
      currentPage: 1,
      perPage: 32,
      selectedVenues: this.selectedData ? this.selectedData : [] as Array<number>,
      disableSelectAllCheckbox: false,
      emit:false
    }
  },
 async mounted() {
    await this.setVenuesSubscription();
    await this.setSubscriptionProducts();
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if("newQuestionnairePopUp" + this.addModalId === modalId && !this.isEdit){
        this.selectedVenues = [];
      }
    });
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if("select-venues-modal" + this.id === modalId && this.isEdited){
        if(!this.emit){
          this.selectedVenues = this.selectedData;
        }
      }
    })
  },

  computed: {
    ...mapGetters("venueSubscriptionModule", {
    venuesSubscription: "GET_VENUES_SUBSCRIPTION",
    subscriptionProducts: "GET_ALL_SUBSCRIPTION_PRODUCTS"
  }),
    totalVenues(): number {
      return this.data.length;
    },
     venuesList(): Array<VenueData> {
      if(this.onlyRead) {
        return this.data.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
      } else {
        const availableVenues = [];
        this.data.forEach((v) => {
          if(!this.expiredVenues.includes(v.id)){
            availableVenues.push(v)
          }
        })
        return availableVenues.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
      }
    },
    allowedVenues(): Array<VenueData> {
      if(this.onlyRead) return;
      const venues: Array<VenueData> = [] as Array<VenueData>;
      if(this.tooltipAudioMessages) {
       this.data.forEach((v) => {
        if (!this.disabledVenues.includes(v.id) && this.hasAudioMessages(v.id)) {
          venues.push(v);
        }
      })
      } else {
        this.data.forEach((v) => {
          if (!this.disabledVenues.includes(v.id) && this.venueSubscription(v.id) !== 1) {
            venues.push(v);
          }
        })
      }
      return venues;
    },
  },

  methods: {
    ...mapActions("venueSubscriptionModule", {
    setVenuesSubscription: "FETCH_VENUES_SUBSCRIPTION",
    setSubscriptionProducts: "FETCH_SUBSCRIPTION_PRODUCTS"
  }),
    toggleAll(checked: boolean): void {
      if (checked) {
        if(this.tooltipAudioMessages) {
          this.data.map( x => {
            if(this.allowedVenues.some(venue => venue.id === x.id) && !this.expiredVenues.some(id => id === x.id)){
             this.selectedVenues.push(x.id);
             this.selectedVenues = [...new Set(this.selectedVenues)]
            }
          });
        } else {
          this.data.map( x => {
            if(this.venueSubscription(x.id) !== 1 && this.allowedVenues.some(venue => venue.id === x.id) && !this.expiredVenues.some(id => id === x.id)){
             this.selectedVenues.push(x.id);
             this.selectedVenues = [...new Set(this.selectedVenues)]
            }
          });
        }
      this.allSelected = true;
    } else {
      this.selectedVenues = [];
      this.allSelected = false;
    }
    },
    venueIsNotAllowed(id: number): boolean {
      if (this.disabledVenues) return this.disabledVenues.includes(id);
    },
    sendData(){
      this.emit = true;
      this.$bvModal.hide('select-venues-modal' + this.id);
    },
    venueSubscription(venueId: number) {
      let subscriptionPlan = this.venuesSubscription.find(s => s.venueId === venueId);
      if (!subscriptionPlan) return "";

      const product: SubscriptionProductsData = this.subscriptionProducts.find(p => p.id === subscriptionPlan.stripeProductWithPriceId);
      if (product){
        return product.songorooPackageType;
      } else if(subscriptionPlan.isCorporate && !subscriptionPlan.stripeProductWithPriceId){
        return 3
      } else {
        return -1;
      }
    },
    hasAudioMessages(venueId: number) {
      let subscriptionPlan = this.venuesSubscription.find(s => s.venueId === venueId);
      if (!subscriptionPlan) return "";
      return subscriptionPlan.additionalServices
    }
  },


  watch: {
    selectedVenues: {
      handler(value: Array<number>) {
        if([...new Set(value)].length - this.disabledVenues.length < this.allowedVenues.length){
        this.allSelected = false;
        }else{
          this.allSelected = true;
        }
        // this.allSelected = this.allowedVenues.length === value.length;
        if (!this.onlyRead) this.$emit("setVenues", [...new Set(value)]);
      }
    },
    allowedVenues: {
      handler(value: Array<VenueData>){
        this.$emit('setAllowedVenues', value)
      }
    },
    disabledVenues: {
      handler(value) {
        if (!this.data) return this.disableSelectAllCheckbox = false;
        this.disableSelectAllCheckbox =  this.disabledVenues.length === this.data.length;
      }
    },
    resetSelected: {
      handler(){
        if(!this.isEdit){
          this.selectedVenues = []
        }
      }
    }
  }
})

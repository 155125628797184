































import Vue, {PropType} from "vue";
import {QuestionaryQuestionData, VenueData} from "@/types";
import i18n from "@/i18n/i18n";

export default Vue.extend({
  name: "custom-select" as string,

  props: {
    data: Array as PropType<Array<QuestionaryQuestionData> | Array<VenueData>>,
    canSelectAll: Boolean as PropType<boolean>,
    venuesSelect: Boolean as PropType<boolean>,
    questionarySelect: Boolean as PropType<boolean>,
    selectedData: Array as PropType<Array<number> | Array<any>>
  },

  data() {
    return {
      allSelected: false,
      selectedValues: !!this.selectedData ? this.selectedData : [],
      i18: i18n
    }
  },

  computed: {
    dropdownTitle(): string {
      if (this.selectedValues.length) {
        if (this.selectedValues.length === 1) {
          const value = this.data.find(v => v.id === this.selectedValues[0]);
          if (this.venuesSelect) return `${value.name}, ${value.city}`;
          if (this.questionarySelect) return `${this.questionaryTranslatedTitle(value.title)}`;
        }
        else if (this.selectedValues.length === this.data.length && this.venuesSelect) return "All venues";
        else return this.venuesSelect ? `${this.$t('Selected venues')}: ${this.selectedValues.length}` : `${this.$t('Selected questions:')} ${this.selectedValues.length}`;
      }

      return this.venuesSelect ? "Select venue" : "Choose campaign questions";
    }
  },

  methods: {
    toggleAll(checked: boolean): void {
      if (checked) this.selectedValues = this.data.map(v => v.id)
      else this.selectedValues = [];
    },
    questionaryTranslatedTitle(title: string): string {
      if (title.includes("sk|") || title.includes("en|")) {
        if (this.i18.locale === "sk") return title.split('|')[1].slice(0, -2);
        else return title.split('|')[2];
      }

      return title;
    }
  },

  watch: {
    selectedValues: {
      handler(value: Array<number>) {
        if (this.venuesSelect) this.allSelected = this.data.length === value.length;
        this.$emit("select", value);
      }
    },
    data: {
      handler(value) {
        if (value) {
          this.selectedValues = this.selectedData;
          if (this.venuesSelect) this.allSelected = this.data.length === this.selectedValues.length;
        }
      },
      deep: true
    }
  }
})
